import * as Tabs from "@radix-ui/react-tabs";
import clx from "classnames";
import kebabCase from "lodash/kebabCase";
import { useTranslation } from "react-i18next";

import { PaymentChannelsEnum } from "@xendit/checkout-utilities";

import InstructionsSteps from "../InstructionsSteps";
import { InvoiceCurrency } from "../../types/checkout";

export interface Instructions {
  title: string;
  steps: Record<string, string>;
  is_unordered?: boolean;
}

export type InstructionsTabsProps = {
  channelCode: string;
  paymentCode: string;
  otherBanksChannel?: string;
  currency?: InvoiceCurrency;
};

function convertChannelByCurrencyForInstructions(
  channel: string,
  currency?: InvoiceCurrency,
  otherBanksChannel?: string
): string {
  switch (currency) {
    case InvoiceCurrency.Vnd:
      if (channel === PaymentChannelsEnum.OTHER_BANKS) {
        return `${channel}_VN`;
      }
      return channel;
    case InvoiceCurrency.Idr:
      if (channel === PaymentChannelsEnum.OTHER_BANKS) {
        if (otherBanksChannel === PaymentChannelsEnum.BNI) {
          return `${PaymentChannelsEnum.OTHER_BANKS}_${PaymentChannelsEnum.BNI}`;
        }

        if (otherBanksChannel === PaymentChannelsEnum.PERMATA) {
          return `${PaymentChannelsEnum.OTHER_BANKS}_${PaymentChannelsEnum.PERMATA}`;
        }

        return channel;
      }

      return channel;
    default:
      return channel;
  }
}

const InstructionsTabs = ({
  channelCode,
  paymentCode,
  otherBanksChannel,
  currency
}: InstructionsTabsProps) => {
  const convertedChannelCode = convertChannelByCurrencyForInstructions(
    channelCode,
    currency,
    otherBanksChannel
  );
  const { t } = useTranslation(kebabCase(convertedChannelCode));

  const paymentMethodInstructions: Record<string, Instructions[]> = t(
    "instructions",
    {
      returnObjects: true
    }
  );

  return (
    <Tabs.Root
      className="flex flex-col"
      defaultValue={Object.keys(paymentMethodInstructions)[0]}
    >
      <Tabs.List
        aria-label={`${convertedChannelCode} payment instructions`}
        className={clx([
          "shrink-0",
          "flex",
          "border-b border-b-xen-gray-300",
          "text-sm md:text-base"
        ])}
      >
        {Object.keys(paymentMethodInstructions)?.map((method) => {
          return (
            <Tabs.Trigger
              key={`${convertedChannelCode}-instructions-${method}`}
              value={method}
              className={clx([
                "flex items-center justify-center",
                "px-4 md:px-6 py-4 -mb-px",
                "uppercase font-semibold text-xen-gray-700 data-[state=inactive]:hover:text-xen-gray-800 data-[state=active]:text-primary",
                "border-b-2 border-transparent data-[state=active]:border-b-primary"
              ])}
              data-testid={`instruction-${method}`}
            >
              {method}
            </Tabs.Trigger>
          );
        })}
      </Tabs.List>
      {Object.keys(paymentMethodInstructions)?.map((method) => {
        return (
          <Tabs.Content
            key={`${convertedChannelCode}-payment-steps-${method}`}
            value={method}
          >
            <div
              className={clx([
                "prose prose-sm max-w-full",
                "prose-a:font-semibold prose-a:text-primary",
                "prose-h3:font-semibold prose-h3:text-lg prose-h3:text-gray-500 prose-h3:mb-4",
                "prose-ol:space-y-2 prose-ul:list-disc",
                "prose-em:font-bold prose-em:text-primary prose-em:not-italic"
              ])}
              data-testid="va-instruction"
            >
              {paymentMethodInstructions[method]?.map((instructions, i) => {
                return (
                  <InstructionsSteps
                    key={`${convertedChannelCode}-${method}-${instructions.title}`}
                    stepIndex={i}
                    channelCode={convertedChannelCode}
                    displayName={convertedChannelCode}
                    paymentCode={paymentCode}
                    instructions={instructions}
                    method={method}
                    i18nKeyPrefix={`instructions.${method}`}
                  />
                );
              })}
            </div>
          </Tabs.Content>
        );
      })}
    </Tabs.Root>
  );
};

export default InstructionsTabs;
