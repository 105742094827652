import { Trans } from "react-i18next";
import kebabCase from "lodash/kebabCase";

import {
  getCompanyCode,
  getIbankingSource,
  getPaymentCodeWithoutPrefix
} from "../../utils/virtual-account";

import { Instructions } from "../InstructionsTabs";

export type InstructionsStepsProps = {
  channelCode: string;
  paymentCode: string;
  displayName: string;
  instructions: Instructions;
  i18nKeyPrefix: string;
  stepIndex: number;
  merchantName?: string;
  method?: string;
};

const InstructionsSteps = ({
  channelCode,
  paymentCode,
  displayName,
  instructions,
  i18nKeyPrefix,
  stepIndex,
  merchantName,
  method
}: InstructionsStepsProps) => {
  const ListElem = instructions.is_unordered ? "ul" : "ol";

  return (
    <div key={`${channelCode}-${method}-${instructions.title}`}>
      <h3 key={`step`}>{instructions.title}</h3>
      <ListElem>
        {Object.keys(instructions.steps).map((step, i) => {
          return (
            <li key={`${channelCode}-${method}-${instructions.title}-${i}`}>
              <Trans
                ns={kebabCase(channelCode)}
                i18nKey={`${i18nKeyPrefix}.${String(stepIndex)}.steps.${step}`}
                values={{
                  channelCode:
                    (channelCode === "7ELEVEN" ? displayName : channelCode) ??
                    "",
                  merchantName: merchantName ?? "",
                  iBankingSource: getIbankingSource(channelCode),
                  companyCode: getCompanyCode(channelCode, paymentCode),
                  fullPaymentCode: paymentCode,
                  paymentCode: getPaymentCodeWithoutPrefix(
                    channelCode,
                    paymentCode
                  )
                }}
                shouldUnescape={true}
                components={{
                  anchor: (
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                      href={getIbankingSource(channelCode)}
                      target="_blank"
                      rel="noreferrer"
                      className="font-semibold text-primary"
                    />
                  ),
                  bold: <em />,
                  // for PH over the counter
                  // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
                  a: <a />,
                  ul: <ul />,
                  li: <li />,
                  // eslint-disable-next-line jsx-a11y/alt-text
                  img: <img />,
                  span: <span />,
                  table: <table />,
                  thead: <thead />,
                  tbody: <tbody />,
                  th: <th />,
                  td: <td />,
                  tr: <tr />,
                  small: <small />
                }}
              />
            </li>
          );
        })}
      </ListElem>
    </div>
  );
};

export default InstructionsSteps;
